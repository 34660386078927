<template>
  <v-dialog
        transition="dialog-transition"
        fullscreen
        persistent
        v-model="confirmCustomers.showDialog"
    >
        <v-card elevation="0" height="100%" width="100%">
            <v-toolbar dark dense color="#FDFDFD" class="subtitle-1" :style="{color: parameters.primaryColor}" elevation="0">
                <v-toolbar-title>Cliente</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn icon dark :color="parameters.primaryColor" draggable @click="confirmCustomers.showDialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-container>
                    <v-list two-line subheader dense>
                        <v-subheader class="subtitle-2 font-weight-bold">Dados do cliente</v-subheader>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="text-left">{{ parameters.store.b2bcpf ? "CPF/CNPJ" : "CNPJ"}}:</v-list-item-title>
                                <v-list-item-subtitle class="text-left">{{ customer.cf1cliente | cpfCnpj }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="text-left">Razão social:</v-list-item-title>
                                <v-list-item-subtitle class="text-left">{{ customer.cf1nome }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="text-left">Nome fantasia:</v-list-item-title>
                                <v-list-item-subtitle class="text-left">{{ customer.cf1fant }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="text-left">Inscrição estadual:</v-list-item-title>
                                <v-list-item-subtitle class="text-left">{{ customer.cf1insest }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="text-left">Celular:</v-list-item-title>
                                <v-list-item-subtitle class="text-left">{{ customer.cf1confone }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="text-left">E-mail:</v-list-item-title>
                                <v-list-item-subtitle class="text-left">{{ customer.cf1email }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="text-left">Telefone:</v-list-item-title>
                                <v-list-item-subtitle class="text-left">{{ customer.cf1fone }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-subheader class="subtitle-2 font-weight-bold">Endereço do cliente</v-subheader>
                        <v-list-item three-line>
                            <v-list-item-avatar>
                                <v-icon color="primary" large>mdi-map-marker-outline</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-left">{{ `${customer.cf1ender1}, ${customer.cf1numero}`}}</v-list-item-title>
                                <v-list-item-title class="text-left">{{ customer.cf1bairro }}</v-list-item-title>
                                <v-list-item-subtitle class="text-left">{{ customer.cf1cep | cep}}</v-list-item-subtitle>
                                <v-list-item-subtitle class="text-left">{{ `${customer.cf3nome}/${customer.cf3estado}`}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-divider></v-divider>
                    <v-card-actions class="d-flex justify-center">
                        <v-btn dark :color="parameters.secondaryColor" @click="confirm">Confirmar</v-btn>
                    </v-card-actions>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        confirmCustomers: {
            get() {
                return this.$store.state.confirmCustomers;
            },
        },
        customer: {
            get() {
                return this.$store.state.confirmCustomers.customer;
            },
        },
    },
    methods:{
        confirm(){
            this.$store.dispatch("confirmCustomers/confirm")
        }
    }
}
</script>

<style>

</style>